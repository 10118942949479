import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "proposedExternalParticipants",
    "standardFee",
    "proposedExternalParticipantTotal",
    "proposedExternalParticipantTotalDisplay",

    "proposedInternalParticipants",
    "internalParticipantFee",
    "proposedInternalParticipantTotal",
    "proposedInternalParticipantTotalDisplay",

    "universityOverheadPercentage",
    "proposedUniversityOverheadTotal",
    "proposedUniversityOverheadTotalDisplay",

    "indirectCostsOverhead",
    "proposedContributionTotal",
    "proposedContributionTotalDisplay",

    "directCostsTotal",
    "proposedDirectCostsTotal",
    "proposedDirectCostsTotalDisplay",

    "nonStaffCostsTotal",
    "proposedNonStaffCostsTotal",
    "proposedNonStaffCostsTotalDisplay",

    "proposedTotal",
    "proposedTotalDisplay",

    "actualStandard",
    "actualExternalParticipantTotal",
    "actualExternalParticipantTotalDisplay",

    "actualInternal",
    "actualInternalParticipantTotal",
    "actualInternalParticipantTotalDisplay",

    "actualUniversityOverheadTotal",
    "actualUniversityOverheadTotalDisplay",

    "actualContributionTotal",
    "actualContributionTotalDisplay",

    "actualDirectCostsTotal",
    "actualDirectCostsTotalDisplay",

    "actualNonStaffCostsTotal",
    "actualNonStaffCostsTotalDisplay",

    "actualTotal",
    "actualTotalDisplay",
  ]

  connect() {
    this.updateProposedTotals()
    this.updateActualTotals()
  }

  updateDirectCosts() {
    this.updateDirectCostsTotal()
    this.updateProposedTotal()
    this.updateActualTotals()
  }

  updateNonStaffCosts() {
    this.updateNonStaffCostsTotal()
    this.updateProposedTotal()
    this.updateActualTotals()
  }

  updateProposedTotals() {
    this.updateProposedExternalParticipantTotal()
    this.updateProposedInternalParticipantTotal()
    this.updateProposedUniversityOverheadTotal()
    this.updateProposedContributionTotal()
    this.updateProposedTotal()
  }

  updateActualTotals() {
    this.updateActualExternalParticipantTotal()
    this.updateActualInternalParticipantTotal()
    this.updateActualUniversityOverheadTotal()
    this.updateActualContributionTotal()
    this.updateActualTotal()
  }

  updateProposedExternalParticipantTotal() {
    const total = this.proposedExternalParticipants * this.standardFee
    this.proposedExternalParticipantTotalTarget.value = total
    this.proposedExternalParticipantTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateProposedInternalParticipantTotal() {
    const total = this.proposedInternalParticipants * this.internalParticipantFee
    this.proposedInternalParticipantTotalTarget.value = total
    this.proposedInternalParticipantTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateProposedUniversityOverheadTotal() {
    const total = this.proposedExternalParticipantTotal * (this.universityOverheadPercentage  / 100) * -1
    this.proposedUniversityOverheadTotalTarget.value = total
    this.proposedUniversityOverheadTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateProposedContributionTotal() {
    const total = (this.proposedExternalParticipantTotal + this.proposedUniversityOverheadTotal) * (this.indirectCostsOverhead  / 100) * -1
    this.proposedContributionTotalTarget.value = total
    this.proposedContributionTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateDirectCostsTotal() {
    const total = -this.directCostsTotal
    this.proposedDirectCostsTotalTarget.value = total
    this.proposedDirectCostsTotalDisplayTarget.innerHTML = this.currencyString(total)

    this.actualDirectCostsTotalTarget.value = total
    this.actualDirectCostsTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateNonStaffCostsTotal() {
    const total = -this.nonStaffCostsTotal
    this.proposedNonStaffCostsTotalTarget.value = total
    this.proposedNonStaffCostsTotalDisplayTarget.innerHTML = this.currencyString(total)

    this.actualNonStaffCostsTotalTarget.value = total
    this.actualNonStaffCostsTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateProposedTotal() {
    const total = this.proposedInternalParticipantTotal
      + this.proposedExternalParticipantTotal
      + this.proposedUniversityOverheadTotal
      + this.proposedContributionTotal
      - this.directCostsTotal
      - this.nonStaffCostsTotal
    this.proposedTotalTarget.value = total
    this.proposedTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateActualExternalParticipantTotal() {
    const total = this.actualStandard * this.standardFee
    this.actualExternalParticipantTotalTarget.value = total
    this.actualExternalParticipantTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateActualInternalParticipantTotal() {
    const total = this.actualInternal * this.internalParticipantFee
    this.actualInternalParticipantTotalTarget.value = total
    this.actualInternalParticipantTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateActualUniversityOverheadTotal() {
    const total = this.actualExternalParticipantTotal * (this.universityOverheadPercentage  / 100) * -1
    this.actualUniversityOverheadTotalTarget.value = total
    this.actualUniversityOverheadTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateActualContributionTotal() {
    const total = (this.actualExternalParticipantTotal + this.actualUniversityOverheadTotal) * (this.indirectCostsOverhead  / 100) * -1
    this.actualContributionTotalTarget.value = total
    this.actualContributionTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  updateActualTotal() {
    const total = this.actualInternalParticipantTotal
      + this.actualExternalParticipantTotal
      + this.actualUniversityOverheadTotal
      + this.actualContributionTotal
      - this.directCostsTotal
      - this.nonStaffCostsTotal
    this.actualTotalTarget.value = total
    this.actualTotalDisplayTarget.innerHTML = this.currencyString(total)
  }

  get proposedExternalParticipants() {
    return this.toNumber(this.proposedExternalParticipantsTarget)
  }
  
  get standardFee() {
    return this.toNumber(this.standardFeeTarget)
  }
  
  get proposedExternalParticipantTotal() {
    return this.toNumber(this.proposedExternalParticipantTotalTarget)
  }

  get proposedInternalParticipants() {
    return this.toNumber(this.proposedInternalParticipantsTarget)
  }

  get internalParticipantFee() {
    return this.toNumber(this.internalParticipantFeeTarget)
  }
  
  get proposedInternalParticipantTotal() {
    return this.toNumber(this.proposedInternalParticipantTotalTarget)
  }

  get universityOverheadPercentage() {
    return this.toNumber(this.universityOverheadPercentageTarget)
  }

  get proposedUniversityOverheadTotal() {
    return this.toNumber(this.proposedUniversityOverheadTotalTarget)
  }

  get indirectCostsOverhead() {
    return this.toNumber(this.indirectCostsOverheadTarget)
  }

  get proposedContributionTotal() {
    return this.toNumber(this.proposedContributionTotalTarget)
  }

  get directCostsTotal() {
    return this.toNumber(this.directCostsTotalTarget)
  }

  get nonStaffCostsTotal() {
    return this.toNumber(this.nonStaffCostsTotalTarget)
  }

  get actualStandard() {
    return this.toNumber(this.actualStandardTarget)
  }

  get actualInternal() {
    return this.toNumber(this.actualInternalTarget)
  }

  get actualInternalParticipantTotal() {
    return this.toNumber(this.actualInternalParticipantTotalTarget)
  }

  get actualExternalParticipantTotal() {
    return this.toNumber(this.actualExternalParticipantTotalTarget)
  }

  get actualUniversityOverheadTotal() {
    return this.toNumber(this.actualUniversityOverheadTotalTarget)
  }

  get actualContributionTotal() {
    return this.toNumber(this.actualContributionTotalTarget)
  }

  toNumber(target) {
    return Number(target.value)
  }

  currencyString(value) {
    return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'})
  }
}
